import Lottie from 'react-lottie';
import React from 'react';
import animationData from '../../public/animations/loaderwhiteweb.json';

interface SpinnerProps {
  height?: number;
  width?: number;
}

const Spinner: React.FC<SpinnerProps> = ({ height, width }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className="content">
      <Lottie options={defaultOptions} height={height || 40} width={width || 40} />
    </div>
  );
};
export default Spinner;
